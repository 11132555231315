import { IPublicClientApplication } from "@azure/msal-browser";
import { loginRequest } from "../authConfig";
import { NwEnvironment } from "../Environment/NwEnvironment";

export function GetUserRoleInfo(instance: IPublicClientApplication, callback: Function) {
    instance.setActiveAccount(instance.getAllAccounts()[0])
    instance.acquireTokenSilent(loginRequest)
        .then(response => response.accessToken)
        .then((accessToken) => {
            fetch(NwEnvironment.apiBaseUrl + 'Rights/',
                {
                    mode: 'cors',
                    method: 'get',
                    headers: new Headers({
                        'Authorization': 'Bearer ' + accessToken
                    }),
                })
                .then(async response => {
                    var resp = await response.json()
                    return resp;
                })
                .then(data => {
                    callback(data)
                });
        })
}