import { IPublicClientApplication } from "@azure/msal-browser";
import { loginRequest } from "../authConfig";
import { NwEnvironment } from "../Environment/NwEnvironment";
import { MaintainerSummary } from "../Interfaces/Models/MaintainerSummary";

export function GetIsGlobalAdmin(instance: IPublicClientApplication, callback: Function) {
    instance.setActiveAccount(instance.getAllAccounts()[0])
    instance.acquireTokenSilent(loginRequest)
        .then(response => response.accessToken)
        .then((accessToken) => {
            fetch(NwEnvironment.apiBaseUrl + 'Maintainer/IsAdmin/',
                {
                    mode: 'cors',
                    method: 'get',
                    headers: new Headers({
                        'Authorization': 'Bearer ' + accessToken
                    }),
                })
                .then(async response => {
                    var resp = await response.json()
                    return resp;
                })
                .then(data => {
                    callback(data)
                });
        })
}

export function CreateMaintainer(maintainer: MaintainerSummary, instance: IPublicClientApplication, callback: Function) {
    var _accessToken: string = "";

    instance.setActiveAccount(instance.getAllAccounts()[0])
    instance.acquireTokenSilent(loginRequest)
        .then(response => _accessToken = response.accessToken)
        .then(() => {
            const requestOptions = {
                method: 'POST',
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + _accessToken
                }),
                body: JSON.stringify(maintainer)
            };
            fetch(NwEnvironment.apiBaseUrl + "Maintainer", requestOptions)
                .then(response => response.json())
                .then(() => callback())
        })
}

export function UpdateMaintainer(maintainer: MaintainerSummary, instance: IPublicClientApplication, callback: Function) {
    var _accessToken: string = "";

    instance.setActiveAccount(instance.getAllAccounts()[0])
    instance.acquireTokenSilent(loginRequest)
        .then(response => _accessToken = response.accessToken)
        .then(() => {
            const requestOptions = {
                method: 'PUT',
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + _accessToken
                }),
                body: JSON.stringify(maintainer)
            };
            fetch(NwEnvironment.apiBaseUrl + "Maintainer", requestOptions)
                .then(response => response.json())
                .then(() => callback())
        })
}

export function GetMaintainers(instance: IPublicClientApplication, callback: Function) {
    instance.setActiveAccount(instance.getAllAccounts()[0])
    instance.acquireTokenSilent(loginRequest)
        .then(response => response.accessToken)
        .then((accessToken) => {
            fetch(NwEnvironment.apiBaseUrl + 'Maintainer/',
                {
                    mode: 'cors',
                    method: 'get',
                    headers: new Headers({
                        'Authorization': 'Bearer ' + accessToken
                    }),
                })
                .then(async response => {
                    var resp = await response.json()
                    return resp;
                })
                .then(data => {
                    var sumaryArr = data as MaintainerSummary[]
                    callback(sumaryArr)
                });
        })
}

export function DeleteMaintainer(maintainerId: string, instance: IPublicClientApplication, callback: Function) {
    var _accessToken: string = "";

    instance.setActiveAccount(instance.getAllAccounts()[0])
    instance.acquireTokenSilent(loginRequest)
        .then(response => _accessToken = response.accessToken)
        .then(() => {
            const requestOptions = {
                method: 'DELETE',
                headers: new Headers({
                    'Authorization': 'Bearer ' + _accessToken
                })
            };
            var url = `${NwEnvironment.apiBaseUrl}Maintainer/${maintainerId}`
            fetch(url, requestOptions)
                .then(() => callback())
        })
}
