import { Modal, Text, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Button } from "@chakra-ui/react";
import Consumer from "../Interfaces/Models/Consumer";

export default function ConfirmDeleteConsumerModal(props: any) {
    const model = props.model as Consumer;
    const onConfirmHandler = () => {
        props.onConfirmCallback();
    }

    return (
        <Modal
            isCentered
            initialFocusRef={props.initialRef}
            isOpen={props.isOpen}
            onClose={props.onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>
                    Wirklich Benutzer mit dem Namen
                    <Text
                        as='kbd'
                        color={'red'}
                        pr='2'
                        pl='2'>
                        {model.firstname} {model.lastname}
                    </Text>
                    löschen?</ModalHeader>
                <ModalCloseButton />
                <ModalBody pb={6}>
                    <Text>
                        Dieser Prozess ist destruktiv und kann nicht wiederhergestellt werden. Alle Daten des Grundstücks sind nach der Löschung nicht mehr zugänglich.
                    </Text>

                </ModalBody>

                <ModalFooter>
                    <Button
                        onClick={onConfirmHandler}
                        color={'white'}
                        bg={'orange'}
                        size='lg'
                        _hover={{
                            bg: 'brand.400',
                            color: 'white',
                        }}
                        mr={3}>
                        Löschen
                    </Button>
                    <Button onClick={props.onClose}>Abbrechen</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}