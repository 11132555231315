import { Grid, Heading, Skeleton } from "@chakra-ui/react"
import { msalInstance } from ".."
import AddMaintainer from "../Components/AddMaintainer"
import { useState } from "react";
import { MaintainerSummary } from "../Interfaces/Models/MaintainerSummary";
import MaintainerCard from "../Components/MaintainerCard";
import { GetMaintainers } from "../Repositories/MaintainerRepository";

let fetchedData: boolean = false;

export default function GlobalAdminPage(props: any) {
    const [loading, setLoading] = useState(true);
    const [maintainers, setMaintainers] = useState(new Array<MaintainerSummary>())
    const callbackSetMaintainers = (data: Array<MaintainerSummary>) => {
        setMaintainers(data)
        setLoading(false)
    }
    const onMaintainerUpserted = () => {
        setLoading(true)
        GetMaintainers(props.msalInstance, callbackSetMaintainers);
    }
    if (!fetchedData) {
        GetMaintainers(props.msalInstance, callbackSetMaintainers);
        fetchedData = true;
    }

    return (
        <>
            <Heading fontSize={'3xl'}
                mt={2}
                ml={1}
                mb={5}>
                Admin
            </Heading>

            <Skeleton isLoaded={!loading}>
                <Grid
                    gap={5}
                    templateColumns={'repeat(auto-fit, 300px)'}>
                    {
                        maintainers.map((maintainer =>
                            <MaintainerCard
                                key={maintainer.id}
                                model={maintainer}
                                instance={msalInstance}
                                callback={onMaintainerUpserted} />
                        ))
                    }

                    <AddMaintainer instance={props.msalInstance} callback={onMaintainerUpserted} />
                </Grid>
            </Skeleton>
        </>
    )
}