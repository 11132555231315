import { msalInstance } from '..';
import { b2cPolicies } from '../authConfig';

export function ErrorComponent({ error }) {
    console.log(error)

    if (error.errorMessage) {
        if (error.errorMessage.indexOf("AADB2C90118") > -1) {
            msalInstance.loginRedirect(b2cPolicies.authorities.forgotPassword)
        }
    }
    return <p>An Error Occurred: {error}</p>;
} 