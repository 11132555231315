import { Box, Flex, useColorModeValue, useDisclosure, Image } from '@chakra-ui/react';
import { ReactNode } from 'react';
import { FiHome, FiLogOut } from 'react-icons/fi';
import { NavItem } from '../Components/NavItem';
import { LinkItemProps } from '../Interfaces/LinkItemProps';
import { SidebarProps } from '../Interfaces/SidebarProps';
import logo from '../Assets/logo.png'
import { PublicClientApplication } from '@azure/msal-browser';

const LinkItems: Array<LinkItemProps> = [
    {
        name: 'Home',
        icon: FiHome
    },
    {
        name: 'Logout',
        icon: FiLogOut
    }
];

export default function SimpleSidebar({ children, msalInstance }: { children: ReactNode, msalInstance: PublicClientApplication }) {
    const { onClose } = useDisclosure();

    return (
        <Box minH="100vh" bg={useColorModeValue('gray.100', 'gray.900')}>
            <SidebarContent
                onClose={() => onClose}
                display={{ base: 'block' }}
                msalInstance={msalInstance}
            />
            <Box ml={{ base: 60 }} p="4">
                {children}
            </Box>
        </Box>
    );
}

export const SidebarContent = ({ onClose, msalInstance, ...rest }: SidebarProps) => {
    return (
        <Box
            bg={useColorModeValue('white', 'gray.900')}
            borderRight="1px"
            borderRightColor={useColorModeValue('gray.200', 'gray.700')}
            w={{ base: 60 }}
            pos="fixed"
            h="full"
            {...rest}>
            <Flex h="20" alignItems="center" mx="8" justifyContent="space-between">
                <Image src={logo} />
            </Flex>
            {LinkItems.map((link) => (
                <NavItem msalInstance={msalInstance} key={link.name} icon={link.icon}>
                    {link.name}
                </NavItem>
            ))}
        </Box>
    );
};
