import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { GetUserRoleInfo } from '../Repositories/UserRightsRepository';
import { RoleInfo } from '../Interfaces/Models/HighestUserRoleInfo';

let statusKnown: boolean = false;

export default function MainContentPage(props: any) {
    let navigate = useNavigate();

    const [roleInfo, setRoleInfo] = useState<RoleInfo | undefined>(undefined)
    const callbackSetRoleInfo = (data: RoleInfo | undefined) => {
        setRoleInfo(data)
        statusKnown = true;
    }

    if (!statusKnown) {
        // GetIsGlobalAdmin(props.msalInstance, callbackSetIsAdmin)
        GetUserRoleInfo(props.msalInstance, callbackSetRoleInfo)
    } else {
        if (roleInfo != undefined && statusKnown) {
            switch (roleInfo.role) {
                case 0:
                    // global admin
                    navigate("/admins/");
                    break;
                case 1:
                    // tenant admin
                    navigate(`/customers/${roleInfo.tenantId}`);
                    break;
                case 2:
                    // customer admin
                    navigate(`/${roleInfo.tenantId}/tenants/${roleInfo.customerId}/`);
                    break;
                default:
                    // default is customer
                    // nothing to see here!
                    break;
            }
        }
    }

    return (<></>);
}