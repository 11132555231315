import { useParams } from "react-router-dom";
import TenantsPage from "./TenantsPage";

export default function CustomersPage(props: any) {
    const msalInstance = props.msalInstance;
    const { id: tenantId } = useParams()

    return (<>
        <TenantsPage msalInstance={props.msalInstance} tenantId={tenantId} />
    </>);
}