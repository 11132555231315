import { Box, Button, useDisclosure } from "@chakra-ui/react";
import React from "react";
import Consumer from "../Interfaces/Models/Consumer";
import { CreateConsumer } from "../Repositories/TenantsRepository";
import UpsertConsumerModal from "./UpsertConsumerModal";

export default function AddConsumer(props: any) {
    const tenantId = props.tenantId
    const initialRef = React.useRef(null)
    const { isOpen, onOpen, onClose } = useDisclosure()

    const callbackOnConsumerCreated = () => {
        props.callback();
        onClose();
    }

    const onConfirmCallback = (data: Consumer) => {
        CreateConsumer(tenantId, data, props.instance, props.ownerId, callbackOnConsumerCreated);
    }

    return (
        <>
            <Box>
                <Button
                    onClick={onOpen}

                    maxW={'300px'}
                    minWidth={'300px'}
                    maxH={'250px'}
                    minHeight={'250px'}

                    boxShadow={'2xl'}
                    rounded={'md'}
                    p={6}

                    color={'white'}
                    bg={'orange'}
                    size='lg'
                    _hover={{
                        bg: 'brand.400',
                        color: 'white',
                    }}>
                    Benutzer Hinzufügen
                </Button>
            </Box>

            <UpsertConsumerModal
                onConfirmCallback={onConfirmCallback}
                initialRef={initialRef}
                isOpen={isOpen}
                onClose={onClose}
                title={"Konsumenten hinzufügen"}
                confirmText="Erstellen"
            />
        </>
    )
}