import { useMsal } from "@azure/msal-react";
import { NwEnvironment } from "../Environment/NwEnvironment";
import Tenant from "../Interfaces/Models/Tenant";
import { loginRequest } from '../authConfig';
import { IPublicClientApplication } from "@azure/msal-browser";
import { TenantSummary } from "../Interfaces/Models/TenantSummary";
import UpsertConsumerRequest from "../Interfaces/Requests/UpsertConsumerRequest";

export default function TenantsRepository() {

}

export function GetTenantSummary(instance: IPublicClientApplication, ownerId: string | undefined, callback: Function): TenantSummary[] {

    var _accessToken: string = "";

    instance.setActiveAccount(instance.getAllAccounts()[0])
    instance.acquireTokenSilent(loginRequest)
        .then(response => _accessToken = response.accessToken)
        .then(() => {
            let result: TenantSummary[] = new Array<TenantSummary>();
            fetch(NwEnvironment.apiBaseUrl + 'Tenant/' + ownerId + '/summary',
                {
                    mode: 'cors',
                    method: 'get',
                    headers: new Headers({
                        'Authorization': 'Bearer ' + _accessToken
                    })
                })
                .then(async response => {
                    var resp = await response.json()
                    return resp;
                })
                .then(data => {
                    var sumaryArr = data.data as TenantSummary[]
                    callback(sumaryArr)
                });
        })

    return Array<TenantSummary>();
}

export function CreateTenant(tenant: Tenant, instance: IPublicClientApplication, ownerId: string | undefined, callback: Function) {
    var _accessToken: string = "";

    instance.setActiveAccount(instance.getAllAccounts()[0])
    instance.acquireTokenSilent(loginRequest)
        .then(response => _accessToken = response.accessToken)
        .then(() => {
            const requestOptions = {
                method: 'POST',
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + _accessToken
                }),
                body: JSON.stringify(tenant)
            };
            fetch(`${NwEnvironment.apiBaseUrl}Tenant/${ownerId}`, requestOptions)
                .then(response => response.json())
                .then(() => callback())
        })
}

export function UpdateTenant(tenant: Tenant, instance: IPublicClientApplication, ownerId: string | undefined, callback: Function) {
    var _accessToken: string = "";

    instance.setActiveAccount(instance.getAllAccounts()[0])
    instance.acquireTokenSilent(loginRequest)
        .then(response => _accessToken = response.accessToken)
        .then(() => {
            const requestOptions = {
                method: 'PUT',
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + _accessToken
                }),
                body: JSON.stringify(tenant)
            };
            fetch(`${NwEnvironment.apiBaseUrl}Tenant/${ownerId}`, requestOptions)
                .then(response => response.json())
                .then(() => callback())
        })
}

export function DeleteTenant(tenantId: string, instance: IPublicClientApplication, ownerId: string | undefined, callback: Function) {
    var _accessToken: string = "";

    instance.setActiveAccount(instance.getAllAccounts()[0])
    instance.acquireTokenSilent(loginRequest)
        .then(response => _accessToken = response.accessToken)
        .then(() => {
            const requestOptions = {
                method: 'DELETE',
                headers: new Headers({
                    'Authorization': 'Bearer ' + _accessToken
                })
            };
            fetch(`${NwEnvironment.apiBaseUrl}Tenant/${ownerId}/${tenantId}`, requestOptions)
                .then(() => callback())
        })
}

export function GetTenant(instance: IPublicClientApplication, tenantId: string | undefined, ownerId: string | undefined, callback: Function) {
    var _accessToken: string = "";
    if (tenantId === undefined) {
        return {};
    }

    instance.setActiveAccount(instance.getAllAccounts()[0])
    instance.acquireTokenSilent(loginRequest)
        .then(response => _accessToken = response.accessToken)
        .then(() => {
            let result: TenantSummary[] = new Array<TenantSummary>();
            fetch(`${NwEnvironment.apiBaseUrl}Tenant/${ownerId}/${tenantId}`,
                {
                    mode: 'cors',
                    method: 'get',
                    headers: new Headers({
                        'Authorization': 'Bearer ' + _accessToken
                    })
                })
                .then(async response => {
                    var resp = await response.json()
                    return resp;
                })
                .then(data => {
                    var tenantData = data as Tenant
                    callback(tenantData)
                });
        })

    return {};
}

export function CreateConsumer(tenantId: string, payload: UpsertConsumerRequest, instance: IPublicClientApplication, ownerId: string | undefined, callback: Function) {
    var _accessToken: string = "";

    instance.setActiveAccount(instance.getAllAccounts()[0])
    instance.acquireTokenSilent(loginRequest)
        .then(response => _accessToken = response.accessToken)
        .then(() => {
            const requestOptions = {
                method: 'POST',
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + _accessToken
                }),
                body: JSON.stringify(payload)
            };
            fetch(`${NwEnvironment.apiBaseUrl}Tenant/${ownerId}/${tenantId}/consumers`, requestOptions)
                .then(response => response.json())
                .then(() => callback())
        })
}

export function DeleteConsumer(tenantId: string, consumerId: string, instance: IPublicClientApplication, ownerId: string | undefined, callback: Function) {
    var _accessToken: string = "";

    instance.setActiveAccount(instance.getAllAccounts()[0])
    instance.acquireTokenSilent(loginRequest)
        .then(response => _accessToken = response.accessToken)
        .then(() => {
            const requestOptions = {
                method: 'DELETE',
                headers: new Headers({
                    'Authorization': 'Bearer ' + _accessToken
                })
            };
            var url = `${NwEnvironment.apiBaseUrl}Tenant/${ownerId}/${tenantId}/consumers/${consumerId}`
            fetch(url, requestOptions)
                .then(() => callback())
        })
}

export function UpdateConsumer(tenantId: string, consumer: UpsertConsumerRequest, instance: IPublicClientApplication, ownerId: string | undefined, callback: Function) {
    var _accessToken: string = "";

    instance.setActiveAccount(instance.getAllAccounts()[0])
    instance.acquireTokenSilent(loginRequest)
        .then(response => _accessToken = response.accessToken)
        .then(() => {
            const requestOptions = {
                method: 'PUT',
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + _accessToken
                }),
                body: JSON.stringify(consumer)
            };
            var url = `${NwEnvironment.apiBaseUrl}Tenant/${ownerId}/${tenantId}/consumers`
            fetch(url, requestOptions)
                .then(response => response.json())
                .then(() => callback())
        })
}