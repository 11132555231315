import './App.css';
import { MsalProvider } from '@azure/msal-react';
import MainLayout from './Pages/MainLayout';
import { ChakraProvider, extendTheme } from '@chakra-ui/react'
import { PublicClientApplication } from '@azure/msal-browser';

function App({ msalInstance }: { msalInstance: PublicClientApplication }) {

  const theme = extendTheme({
    colors: {
      brand: {
        100: "#FCEFD4",
        200: "#FADBAB",
        300: "#F0BD7E",
        400: "#E29E5C",
        500: "#D0742C",
        600: "#B25820",
        700: "#954016",
        800: "#782B0E",
        900: "#631C08",
      },
      orange: '#D0742C'
    },
  })

  return (
    <MsalProvider instance={msalInstance}>
      <ChakraProvider theme={theme}>
        <MainLayout msalInstance={msalInstance} />
      </ChakraProvider>
    </MsalProvider>
  )
}

export default App;
