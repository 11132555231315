import { useDisclosure, Heading, Skeleton, Grid, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, FormControl, FormLabel, Input, ModalFooter, Button } from "@chakra-ui/react";
import React, { useState } from "react";
import { msalInstance } from "..";
import AddTenant from "../Components/AddTenant";
import TenantCard from "../Components/TenantCard";
import { TenantSummary } from "../Interfaces/Models/TenantSummary";
import { GetTenantExport } from "../Repositories/ExportsRepository";
import { GetTenantSummary } from "../Repositories/TenantsRepository";

let fetchedData: boolean = false;

export default function TenantsPage(props: any) {
    const [loading, setLoading] = useState(true);
    const initialRef = React.useRef(null)
    const { isOpen, onOpen, onClose } = useDisclosure()

    const [tenants, setTenants] = useState(new Array<TenantSummary>())
    const callbackSetTenants = (data: Array<TenantSummary>) => {
        setTenants(data)
        setLoading(false)
    }
    const onTenantUpserted = () => {
        setLoading(true)
        GetTenantSummary(props.msalInstance, props.tenantId as string, callbackSetTenants);
    }

    if (!fetchedData) {
        GetTenantSummary(props.msalInstance, props.tenantId as string, callbackSetTenants);
        fetchedData = true;
    }

    const [currentTenant, setCurrentTenant] = useState<TenantSummary>()
    const [fromDate, setFromDate] = useState<string>("")
    const [toDate, setToDate] = useState<string>("")

    const onOpenExportModal = (e: any) => {
        var currentT = e as TenantSummary
        onOpen()
        setCurrentTenant(currentT)
    };

    const onFromDateChange = (e: any) => { setFromDate(e.target.value) };
    const onToDateChange = (e: any) => { setToDate(e.target.value) };
    const onClickExecute = () => {
        GetTenantExport(msalInstance,
            currentTenant!.id,
            {
                "from": fromDate,
                "to": toDate
            },
            (data: Blob) => {
                const fileURL = window.URL.createObjectURL(data);
                let alink = document.createElement('a');
                alink.href = fileURL;
                alink.download = currentTenant!.name + "_" + fromDate + "_" + toDate + '.csv';
                alink.click();

                window.URL.revokeObjectURL(fileURL)
                onClose()
            });
    };

    return (
        <>
            <Heading fontSize={'3xl'}
                mt={2}
                ml={1}
                mb={5}>
                HOME
            </Heading>

            <Skeleton isLoaded={!loading}>
                <Grid
                    gap={5}
                    templateColumns={'repeat(auto-fit, 300px)'}>
                    {
                        tenants.map((tenant =>
                            <TenantCard
                                key={tenant.id}
                                model={tenant}
                                ownerId={props.tenantId}
                                instance={msalInstance}
                                callbackSetExportData={onOpenExportModal}
                                callback={onTenantUpserted} />
                        ))
                    }
                    <AddTenant instance={props.msalInstance} ownerId={props.tenantId} callback={onTenantUpserted} />
                </Grid>
            </Skeleton>

            <Modal
                isCentered
                initialFocusRef={initialRef}
                isOpen={isOpen}
                onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>CSV Export erstellen für {currentTenant?.name}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb={6}>
                        <FormControl>
                            <FormLabel>
                                von
                            </FormLabel>
                            <Input
                                type={'date'}
                                ref={initialRef}
                                placeholder='2002-08-01'
                                onChange={onFromDateChange} />
                        </FormControl>
                        <FormControl mt={4}>
                            <FormLabel>
                                bis
                            </FormLabel>
                            <Input
                                type={'date'}
                                placeholder='2002-09-01'
                                onChange={onToDateChange} />
                        </FormControl>
                    </ModalBody>

                    <ModalFooter>
                        <Button
                            onClick={onClickExecute}
                            color={'white'}
                            bg={'orange'}
                            size='lg'
                            _hover={{
                                bg: 'brand.400',
                                color: 'white',
                            }}
                            mr={3}>
                            Erstellen
                        </Button>
                        <Button onClick={onClose}>Abbrechen</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}