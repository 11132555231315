import { InteractionType } from '@azure/msal-browser';
import { MsalAuthenticationTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import { Box, Spinner } from '@chakra-ui/react';
import { Routes, Route, HashRouter } from 'react-router-dom';
import { ErrorComponent } from './AuthErrorComponent';
import SimpleSidebar from './InternalPage';
import MainContentPage from './MainContentPage';
import TenantDetailPage from './TenantDetailPage';
import AdminsPage from './AdminsPage';
import CustomersPage from './CustomersPage';

function MainLayout(props: any) {
    const msalInstance = props.msalInstance;

    return (
        <>
            <MsalAuthenticationTemplate
                interactionType={InteractionType.Redirect}
                errorComponent={ErrorComponent}>

                <HashRouter>
                    <Routes>
                        <Route
                            path='/'
                            element={
                                <SimpleSidebar msalInstance={msalInstance} children={<MainContentPage msalInstance={msalInstance} />} />
                            } />
                        <Route
                            path='/admins'
                            element={
                                <SimpleSidebar msalInstance={msalInstance} children={<AdminsPage msalInstance={msalInstance} />} />
                            } />
                        <Route
                            path='/customers/:id'
                            element={
                                <SimpleSidebar msalInstance={msalInstance} children={<CustomersPage msalInstance={msalInstance} />} />
                            } />
                        <Route
                            path='/:ownerId/tenants/:id'
                            element={
                                <SimpleSidebar msalInstance={msalInstance} children={<TenantDetailPage msalInstance={msalInstance} />} />
                            } />
                    </Routes>
                </HashRouter>
            </MsalAuthenticationTemplate >
            <UnauthenticatedTemplate>
                <Box position={'relative'}>
                    <Spinner
                        height={100}
                        width={100}
                        color='orange' />
                </Box>
            </UnauthenticatedTemplate>
        </>
    )
}

export default MainLayout;