import { Link, Flex, Icon } from "@chakra-ui/react";
import { msalConfig } from "../authConfig";
import { NavItemProps } from "../Interfaces/NavItemProps";

export const NavItem = ({ icon, msalInstance, children, ...rest }: NavItemProps) => {
    const onLogoutClicked = () => {
        const currentAccount = msalInstance.getAllAccounts()[0];
        const logoutUrl = msalConfig.auth.postLogoutRedirectUri
        msalInstance.logoutRedirect({
            account: currentAccount,
            postLogoutRedirectUri: logoutUrl
        })
    }

    if (children === 'Logout') {
        return (
            <Link
                onClick={onLogoutClicked}
                style={{ textDecoration: 'none' }}
                _focus={{ boxShadow: 'none' }}>
                <Flex
                    align="center"
                    p="4"
                    mx="4"
                    borderRadius="lg"
                    role="group"
                    _hover={{
                        bg: 'orange',
                        color: 'white',
                    }}
                    {...rest}>
                    {icon && (
                        <Icon
                            mr="4"
                            fontSize="16"
                            _groupHover={{
                                color: 'white',
                            }}
                            as={icon}
                        />
                    )}
                    {children}
                </Flex>
            </Link>
        );
    } else {
        return (
            <Link
                href="/"
                style={{ textDecoration: 'none' }}
                _focus={{ boxShadow: 'none' }}>
                <Flex
                    align="center"
                    p="4"
                    mx="4"
                    borderRadius="lg"
                    role="group"
                    _hover={{
                        bg: 'orange',
                        color: 'white',
                    }}
                    {...rest}>
                    {icon && (
                        <Icon
                            mr="4"
                            fontSize="16"
                            _groupHover={{
                                color: 'white',
                            }}
                            as={icon}
                        />
                    )}
                    {children}
                </Flex>
            </Link>
        );
    }

};
